import {
  Checkbox,
  TableCell,
  TableCellProps,
  TableHead,
  TableRow,
} from '@mui/material';
import { FC, useContext, useMemo } from 'react';
import { FIXTURE_TABLE_HEADCELLS } from '@/components/FixturesTable/constants';
import { FixturesTableContext } from '@/components/FixturesTable/context/FixturesTableContext';
import { THEME_MODE } from '@/contexts/theme/constants';

const FixtureTableCell: FC<TableCellProps> = ({ sx = {}, ...props }) => (
  <TableCell
    sx={{
      borderBottom: 'none',
      background: (theme) =>
        theme.palette.mode === THEME_MODE.DARK ? 'inherit' : '',
      ...sx,
    }}
    {...props}
  />
);

export const FixturesTableHead = () => {
  const {
    data: { fixturesWithIncidents },
    checkboxes: { selectedFixtures, handleSelectAllFixtures },
  } = useContext(FixturesTableContext);

  const selectedFixturesCount = useMemo(
    () => selectedFixtures.length,
    [selectedFixtures],
  );

  const fixturesWithIncidentsCount = useMemo(
    () => fixturesWithIncidents?.collections.data.length || 0,
    [fixturesWithIncidents?.collections.data],
  );

  return (
    <TableHead>
      <TableRow
        sx={{
          background: (theme) =>
            theme.palette.mode === THEME_MODE.DARK
              ? theme.palette.secondary.dark
              : theme.palette.background.default,
          boxShadow: 2,
        }}
      >
        <FixtureTableCell padding='checkbox'>
          <Checkbox
            color='primary'
            indeterminate={
              !!selectedFixturesCount &&
              selectedFixturesCount < fixturesWithIncidentsCount
            }
            checked={
              !!fixturesWithIncidentsCount &&
              selectedFixturesCount === fixturesWithIncidentsCount
            }
            onChange={handleSelectAllFixtures}
          />
        </FixtureTableCell>
        <FixtureTableCell />
        {FIXTURE_TABLE_HEADCELLS.map(({ id, name }) => (
          <FixtureTableCell
            key={id}
            align={typeof id === 'string' ? 'left' : 'center'}
            sx={{
              fontSize: '.75rem',
              fontWeight: 700,
              lineHeight: 1.25,
            }}
          >
            {typeof id === 'string'
              ? name
              : `${name} (${fixturesWithIncidents?.summary.find(
                  (issue) => issue.issueId === id,
                )?.totalIssuesCount})`}
          </FixtureTableCell>
        ))}
        <FixtureTableCell />
      </TableRow>
    </TableHead>
  );
};
