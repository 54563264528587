import { Issue } from '@/contexts/fixturesWithIncidents/types';

export const FIXTURE_TABLE_HEADCELLS = [
  {
    id: 'sportIcon',
    name: '',
  },
  {
    id: 'fixtureName',
    name: 'Fixture Name',
  },
  {
    id: 'startTime',
    name: 'Start Time',
  },
  {
    id: 'clockTime',
    name: 'Clock Time',
  },
  {
    id: 'periodMatch',
    name: 'Period',
  },
  {
    id: 'fixtureStatus',
    name: 'Fixture Status',
  },
  {
    id: 'assigned',
    name: 'Assigned',
  },
  {
    id: Issue.SCORER_DISCONNECTED,
    name: 'Scorer disconnected',
    issueIndex: 0,
    issueId: 1,
  },
  {
    id: Issue.COLL_STAT_SUSPENDED,
    name: 'Coll. stat. suspended',
    issueIndex: 1,
    issueId: 2,
  },
  {
    id: Issue.COLLECTION_STATUS,
    name: 'Coll. status ≠ PMCC >15min post-game',
    issueIndex: 3,
    issueId: 4,
  },
  // { id: Issue.PLAY_STOPPED, name: 'Play stopped > 3 min', issueIndex: 2 },

  // { id: Issue.FLAGS_UNCONFIRMED, name: 'Flags unconfirmed > 0', issueIndex: 4 },
  // {
  //   id: Issue.OUTDATED_APP_VERSION,
  //   name: 'Outdated App Version',
  //   issueIndex: 5,
  // },
  // { id: Issue.MATCH_NOT_LIVE, name: 'Match not live', issueIndex: 6 },
  // {
  //   id: Issue.NO_COMMENT_ON_ACTIONS,
  //   name: 'No comment on action',
  //   issueIndex: 7,
  // },
  // {
  //   id: Issue.GDS_NOT_ACKNOWLEDGED_PRESENCE,
  //   name: 'GDS not acknowledged presence',
  //   issueIndex: 8,
  // },
  // { id: Issue.MARKET_CLOSED, name: 'Market closed', issueIndex: 9 },
];
