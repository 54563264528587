import { Box, Theme, useTheme } from '@mui/material';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import SportsBasketballIcon from '@mui/icons-material/SportsBasketball';
import SportsFootballIcon from '@mui/icons-material/SportsFootball';
import SportsVolleyballIcon from '@mui/icons-material/SportsVolleyball';
import { Icon } from '@mdi/react';
import { mdiBilliardsRack, mdiHockeyPuck } from '@mdi/js';
import { FC } from 'react';
import { SPORT_ID, SPORT_NAME } from '@/service/constants';
import { CustomTooltip } from '@/components/StatsCompareTable/StyledStatsComponents';

type SportIconsProp = {
  sportId: number;
};
export const SPORT_ICON: Record<number, (theme: Theme) => JSX.Element> = {
  [SPORT_ID.SOCCER]: (theme) => (
    <SportsSoccerIcon sx={{ color: theme.typography.caption.color }} />
  ),
  [SPORT_ID.BASKETBALL]: (theme) => (
    <SportsBasketballIcon sx={{ color: theme.typography.caption.color }} />
  ),
  [SPORT_ID.AMERICAN_FOOTBALL]: (theme) => (
    <SportsFootballIcon sx={{ color: theme.typography.caption.color }} />
  ),
  [SPORT_ID.ICE_HOCKEY]: (theme) => (
    <Icon
      data-testid='hockey-icon'
      size='1.5em'
      path={mdiHockeyPuck}
      color={theme.typography.caption.color}
    />
  ),
  [SPORT_ID.VOLLEYBALL]: (theme) => (
    <SportsVolleyballIcon sx={{ color: theme.typography.caption.color }} />
  ),
  [SPORT_ID.BEACH_VOLLEYBALL]: (theme) => (
    <SportsVolleyballIcon sx={{ color: theme.typography.caption.color }} />
  ),
  [SPORT_ID.SNOOKER]: (theme) => (
    <Icon
      data-testid='snooker-icon'
      size='1.7em'
      path={mdiBilliardsRack}
      color={theme.typography.caption.color}
    />
  ),
};

export const SportIcons: FC<SportIconsProp> = ({ sportId }) => {
  const theme = useTheme();
  if (!sportId) return null;

  return (
    <Box display='flex' alignItems='center' justifyContent='center'>
      <CustomTooltip title={SPORT_NAME[sportId as keyof typeof SPORT_NAME]}>
        {SPORT_ICON[sportId](theme)}
      </CustomTooltip>
    </Box>
  );
};
